<template>
  <svg
    width="82.91"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 82.91 22.78"
  >
    <g opacity="0.5" class="scdown-a">
      <polyline
        fill="none"
        stroke="#000"
        stroke-miterlimit="10"
        stroke-width="1.7"
        class="scdown-b"
        points="82.52 0.76 41.45 21.82 0.39 0.76"
      />
    </g>
  </svg>
</template>

<script>
export default {};
</script>

<style>
</style>